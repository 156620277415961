<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <role-form
      v-if="featurePermissions.length > 0"
      :featurePermissions="featurePermissions"
      :roleResource="roleResource"
      :userType="userTypeId"
      :formMode="formMode"
    ></role-form>
  </div>
</template>

<script>
import RoleForm from '../Forms/RoleForm.vue';
import RoleMixins from '@/pages/Account/Role/role.mixins';
import { UnSaveChangesMixins, ResetHeadingTitleMixins } from '@/core/mixins';
import RoleService from '@/services/role.service';
import { RolePermissionsHelper } from '@/core/helpers';
import { FORM_MODES } from '@/core/constants';
export default {
  name: 'LimsRoleEdit',
  mixins: [UnSaveChangesMixins, RoleMixins, ResetHeadingTitleMixins],
  components: {
    RoleForm,
  },
  props: {
    roleId: {
      type: String,
      required: true,
    },
    isViewMode: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    formMode() {
      return this.isViewMode ? FORM_MODES.VIEW : FORM_MODES.EDIT;
    },
    userTypeId() {
      if (this.roleResource) {
        return this.roleResource.information.userTypeId;
      }
      return 0;
    },
  },
  data() {
    return {
      roleResource: {},
      featurePermissions: [],
    };
  },
  methods: {
    async roleResolver(roleId) {
      if (roleId) {
        const { data } = await RoleService.findOne(roleId);
        this.featurePermissions = data.permissions;
        const { permissions, ...formData } = data;
        this.roleResource = {
          permissions: RolePermissionsHelper.buildPermissionsModel(permissions),
          ...formData,
        };
      }
    },
  },
  created() {
    this.roleResolver(this.roleId);
  },
};
</script>

<style lang="scss" scoped></style>
